import React from 'react'

import { graphql } from 'gatsby'

import 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SEO from '../../components/seo'
import SiteHead from '../../components/site-head'
import PageContent from '../../components/page-content'
import ArticleContainer from '../../components/article-container'
import ArticleHeading from '../../components/article-heading'
import Section from '../../components/section'
import RichArticle from '../../components/rich-article'
import ButtonLink from '../../components/button-link'
import SocialIcons from '../../components/social-icons'
import SiteFoot from '../../components/site-foot'
import { AnimateIn } from '../../components/animate-in'

const AboutPage = ({ data }) => {
  const { entry, contactDetails } = data

  return (
    <>
      <SEO data={entry.seomatic} />
      <SiteHead />
      <PageContent>
        <ArticleContainer>
          <ArticleHeading heading={entry.title} />
          <RichArticle blocks={entry.richArticle} />
          <AnimateIn>
            <Section tw="mb-8!">
              <div tw="flex flex-wrap justify-between -mx-2">
                <div tw="w-full px-2 xl:w-1/2 mb-8 xl:mb-0">
                  {entry.getInTouchBody && (
                    <>
                      <div
                        tw="mb-8 text-3xl font-normal"
                        dangerouslySetInnerHTML={{
                          __html: entry.getInTouchBody,
                        }}
                      ></div>
                      {contactDetails.eMailAddress && (
                        <ButtonLink
                          text="Get in touch"
                          to={`mailto:${contactDetails.eMailAddress}`}
                          isExternal
                        />
                      )}
                    </>
                  )}
                </div>
                <div tw="w-full px-2 xl:w-1/2 font-normal">
                  <div tw="flex flex-wrap flex-row xl:justify-end -mx-6">
                    <div tw="px-6 mb-4 xl:mb-0">
                      {contactDetails.address && (
                        <div>
                          <h3 tw="mb-4">Find us here</h3>
                          <address
                            tw="not-italic mb-6"
                            dangerouslySetInnerHTML={{
                              __html: contactDetails.address,
                            }}
                          ></address>
                          {contactDetails.googleMapsLink.length ? (
                            <a
                              tw="underline hover:no-underline focus:no-underline text-xs mt-2 block"
                              href={contactDetails.googleMapsLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open in Google Maps
                            </a>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div tw="px-6">
                      <h3 tw="mb-4">Or get in touch</h3>
                      <div tw="mb-8">
                        {contactDetails.eMailAddress && (
                          <p tw="mb-1">
                            <a href={`mailto:${contactDetails.eMailAddress}`}>
                              <FontAwesomeIcon
                                tw="mr-2"
                                icon={['fas', 'envelope']}
                              />
                              Drop us a line
                            </a>
                          </p>
                        )}
                        {contactDetails.phoneNumber && (
                          <p>
                            <a href={`tel:${contactDetails.phoneNumber}`}>
                              <FontAwesomeIcon
                                tw="mr-2"
                                icon={['fas', 'phone']}
                              />
                              {contactDetails.phoneNumber}
                            </a>
                          </p>
                        )}
                      </div>
                      <h3 tw="mb-2">Follow us here</h3>
                      <SocialIcons />
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </AnimateIn>
        </ArticleContainer>
        <SiteFoot />
      </PageContent>
    </>
  )
}

export default AboutPage

export const query = graphql`
  query($id: ID) {
    entry: craftAboutAboutEntry(remoteId: { eq: $id }) {
      seomatic {
        ...SEO
      }
      title
      getInTouchBody
      richArticle {
        ...RichArticleTextBlock
        ...RichArticleQuoteBlock
        ...RichArticleFullWidthImageBlock
        ...RichArticleHalfWidthImageBlock
        ...RichArticleTextAndImageBlock
        ...RichArticleImageRowBlock
        ...RichArticleImageAndTextGridBlock
        ...RichArticleVideoBlock
        ...RichArticleAmbientVideoBlock
      }
    }
    contactDetails: craftContactDetailsGlobalSet {
      address
      phoneNumber
      eMailAddress
      googleMapsLink
    }
  }
`
